
class BaseController {

	constructor(container) {
		this.container = container;
	}

	setup() {

	}

	terminate() {

	}
}

export default BaseController;
