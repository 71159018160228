import $ from 'node_modules/jquery';

import Helpers from 'common/Helpers';

// Not the best implementation, but one that matches our use cases
//
// Inspired by:
// Alertify
class AlertManager {

	static getClassName() {
		return 'AlertManager';
	}

	/**
	 * Method to retrieve the shared instance
	 *
	 * @returns {AlertManager}
	 */
	static shared() {
		if (!Helpers.isDefined(this.instance)) {
			this.instance = new AlertManager();
		}

		return this.instance;
	}

	/**
	 * Must not be called directly, must use shared method.
	 */
	constructor() {
		this.$container = null;
		this.pending = [];
		this.displayed = null;
		this.options = {

		};
	}

	// Main methods

	/**
	 * Adds a managed alert instance
	 *
	 * @param {Alert} alert
	 */
	add(alert) {
		// Binding if there was no items to monitor before
		if (this.pending.length === 0 && this.displayed === null) {
			this.setup();
		}

		this.pending.push(alert);

		this.manageDisplay();
	}

	/**
	 * Removes a managed alert instance
	 *
	 * @param {Alert} alert
	 */
	remove(alert) {
		let index = -1;
		for (let i = 0; i < this.pending.length; i++) {
			const item = this.pending[i];

			if (item === alert) {
				index = i;
			}
		}

		if (index !== -1) {
			this.pending.splice(index, 1);
		}

		//
		this.hide(alert, () => {
			//
			this.manageDisplay();

			// Unbinding if no items left to monitor
			if (this.pending.length === 0 && this.displayed === null) {
				this.teardown();
			}
		});
	}

	// Internal

	/**
	 * Internal
	 */
	setup() {
		if (this.$container !== null) {
			return;
		}

		this.$container = $('<div id="alert-container"></div>');
		$('body').append(this.$container);

		$(document).on('keyup.alertHandleEscapeKey', (e) => { this.handleEscapeKey(e); });

		setTimeout(() => {
			this.$container.addClass('show');
		}, 1);
	}

	/**
	 * Internal
	 */
	teardown() {
		if (this.$container === null) {
			return;
		}

		this.$container.remove();
		this.$container = null;

		$(document).off('keyup.alertHandleEscapeKey');
	}

	/**
	 * Internal
	 */
	manageDisplay() {
		if (this.displayed !== null) {
			// Displaying only 1
			return;
		}

		if (this.pending.length === 0) {
			// Nothing to display
			return;
		}

		const displayableAlert = this.pending.shift();
		this.display(displayableAlert);
	}

	/**
	 * Internal
	 */
	display(alert) {
		const $alert = alert.render();
		this.$container.append($alert);
		this.displayed = {alert: alert, display: $alert};

		alert.didDisplay();
	}

	/**
	 * Internal
	 */
	hide(alert, callback = () => {}) {
		for (let i = 0; i < this.pending.length; i++) {
			const pending = this.pending[i];

			if (pending === alert) {
				this.pending.splice(i, 1);

				alert.didHide();

				return;
			}
		}

		alert.willHide();

		setTimeout(() => {
			this.displayed.display.remove();
			this.displayed = null;

			alert.didHide();

			callback();
		}, 150);
	}

	/**
	 *
	 */
	handleEscapeKey(event) {
		// Esc key = 27
		if (event.which === 27 && this.displayed !== null) {
			this.displayed.alert.escape();
		}
	}
}

/**
 * Usage:
 * const alert = new Alert(Alert.MESSAGE, 'Blabla', {});
 * alert.show();
 * or
 * Alert.confirm('Blabla', {});
 */
class Alert {
	static get MESSAGE() { return 'message'; }
	static get CONFIRM() { return 'confirm'; }

	static getClassName() {
		return 'Alert';
	}

	/**
	 *
	 * @param {String} type
	 * @param {String|Node} content
	 * @param {Object} options
	 */
	constructor(type, content, options = {}) {
		this.type = type;
		this.content = content;

		this.options = $.extend({}, {
			okButton: 'Ok',
			cancelButton: 'Cancel',
			onDisplay: (alert) => {},
			onPreOk: (alert) => { return true; },
			onOk: (alert) => {},
			onCancel: (alert) => {},
			width: 'auto',
			cancelOnEscape: true,
			okOnEscape: true,
			buttonsAlwaysVisible: true,
		}, options);

		this.$display = null;
	}

	// Main methods

	static message(content, options = {}) {
		return Alert.auto(Alert.MESSAGE, content, options);
	}

	static confirm(content, options = {}) {
		return Alert.auto(Alert.CONFIRM, content, options);
	}

	// Other methods

	static auto(type, content, options = {}) {
		const n = new Alert(type, content, options);
		n.show();
		return n;
	}

	/**
	 * Shows the alert manually
	 */
	show() {
		AlertManager.shared().add(this);
	}

	/**
	 * Hides the alert manually
	 */
	hide() {
		AlertManager.shared().remove(this);
	}

	// Internal

	/**
	 * Internal
	 */
	render() {
		const $content = $(`<div class="alert-content"></div>`);
		$content.append(this.content);
		$content.find('input, select, textarea').attr('tabindex', 1);
		if (this.options.buttonsAlwaysVisible) {
			$content.addClass('buttonsalwaysvisible');
		}
		$content.find('form').on('submit', (e) => {
			e.preventDefault();

			this.handleOkButton(e);
		});

		const $buttons = $(`<div class="alert-buttons"></div>`);
		if ([Alert.CONFIRM].indexOf(this.type) !== -1) {
			const $cancelButton = $(`<button class="button button-cancel" tabindex="3">${this.options.cancelButton}</button>`);
			$buttons.append($cancelButton);

			$cancelButton.on('click', (e) => { this.handleCancelButton(e); });
		}
		const $okButton = $(`<button class="button button-ok" tabindex="2">${this.options.okButton}</button>`);
		$buttons.append($okButton);
		$okButton.on('click', (e) => { this.handleOkButton(e); });

		this.$display = $(`<div class="alert alert-${this.type}" style="width: ${this.options.width};"></div>`);
		this.$display.append($content);
		this.$display.append($buttons);

		return this.$display;
	}

	/**
	 * Internal
	 */
	didDisplay() {
		setTimeout(() => {
			this.$display.addClass('show');
		}, 1);

		const $input = this.$display.find('input:not([type="hidden"]), select, textarea');
		if ($input.length > 0) {
			$input.eq(0).select().focus();
		} else {
			this.$display.find('.button-ok').focus();
		}

		this.options.onDisplay(this);
	}

	/**
	 * Internal
	 */
	willHide() {
		this.$display.removeClass('show');
	}

	/**
	 * Internal
	 */
	didHide() {
		this.$display = null;
	}

	/**
	 * Internal
	 */
	escape() {
		if (this.options.cancelOnEscape) {
			const $cancelButton = this.$display.find('.button-cancel');
			if ($cancelButton.length > 0) {
				$cancelButton.trigger('click');
				return;
			}
		}

		if (this.options.okOnEscape) {
			const $okButton = this.$display.find('.button-ok');
			if ($okButton.length > 0) {
				$okButton.trigger('click');
				// eslint-disable-next-line no-useless-return
				return;
			}
		}
	}

	/**
	 * Internal
	 */
	handleCancelButton(e) {
		this.hide();
		this.options.onCancel(this);
	}

	/**
	 * Internal
	 */
	handleOkButton(e) {
		if (this.options.onPreOk(this)) {
			this.hide();
			this.options.onOk(this);
		}
	}
}

export default Alert;
