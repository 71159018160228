class ApiHelper {
    static sendRequest(url, method = 'GET', data = null) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open(method, url, true);
            xhr.onload = () => {
                if (xhr.status === 200) {
                    try {
                        const response = JSON.parse(xhr.responseText);
                        resolve(response);
                    } catch (e) {
                        reject(new Error(`Error parsing response: ${e.message}`));
                    }
                } else {
                    reject(new Error(`HTTP error! Status: ${xhr.status}`));
                }
            };
            xhr.onerror = () => {
                reject(new Error('Network error'));
            };
            xhr.send(data);
        });
    }
    
    static handleError(error) {
        console.error('API Error:', error.message);
    }
}

export default ApiHelper;
