class ProjectFormRenderer {
    static render() {
        return `
            <div class="project-form-header">
                <div class="title-header">
                    <div class="title">Création du projet</div>
                </div>
            </div>
            <form id="create-project-form" enctype="multipart/form-data">
                <div class="input-group">
                    <div id="project-name-container">
                        <label for="project-name" id="title-project-name">Nom du projet</label>
                        <input type="text" name="project-name" placeholder="Nom du projet" id="project-name" required>
                    </div>
                    <div id="project-files-container">
                        <label for="project-files" id="title-project-files">Fichiers</label>
                        <input type="file" id="project-files" name="files[]" accept=".csv,image/*" multiple required>
                    </div>
                </div>
                <div id="project-submit">
                    <button type="submit" id="project-submit-button">Créer le projet</button>
                </div>
            </form>
        `;
    }
}

export default ProjectFormRenderer;
