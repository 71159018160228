class UserAccountRenderer {
    
    constructor() {
    }

    render(whoami) {
        return `
            <div class="user-account-header">
                <div class="title-header">
                    <div class="title">Repérages</div>
                </div>
            </div>
            <div id="user-account-wrapper" class="scrollable-content">
                <div class="user-info">
                    <div id="user-message">Connecté en tant que :</div>
                    <div id="user">${whoami.username}</div>
                    <div id="user-logout">
                        <div id="user-logout-message">Se déconnecter</div>
                    </div>
                </div>
            </div>
        `;
    }
}

export default UserAccountRenderer;
