import Helpers from 'common/Helpers';
import OutputHelpers from 'common/OutputHelpers';

import Reperages from 'common/reperages/Reperages';

class PointsListRenderer {

    constructor() {
        this.filtersOptionsOpen = false;
        this.pointFilters = {
            filterName: '',
        };
    }

    render(data) {
        return `
            <div id="options">
                <div id="point-show-filter" data-tooltip="Afficher ou masquer un pin">
                    <span class="icomoon-gear" id="show-filter-button"></span>
                </div>
                <div id="point-search-filter" data-tooltip="Options de la liste">
                    <span class="icomoon-search" id="search-filter-button"></span>
                </div>
            </div>
            <div class="point-filters ${this.filtersOptionsOpen ? 'visible' : ''}">
                <div class="point-name">
                    <!--<label for="point-filter-name">Nom</label>-->
                    <input type="text" name="point-filter-name" id="point-filter-name" value="${this.pointFilters.filterName}">
                </div>
            </div>
            <div id="points-list-wrapper" class="scrollable-content">
                ${OutputHelpers.map(OutputHelpers.sort(data, ['name'], 'asc'), point => `
                    <div class="point" data-id="${point.id}" data-name="${point.name}">
                        <div class="info-wrapper">
                            ${PointsListRenderer.renderInfoWrapper(point)}
                        </div>
                        <div class="options-wrapper">
                            <div class="show-pin hidden">
                                <span class="icomoon-view-show displayed" id="pin-display-toggle" data-tooltip="Cliquer pour afficher ou masquer le pin sur la carte"></span>
                            </div>
                        </div>
                    </div>
                `)}
            </div>
            <div id="select-deselect-buttons" class="hidden">
                <a href="#" class="button-select multiple-select-all" data-tooltip="Afficher tous les pins sur la carte"><span class="icomoon-select-all show-hide"></span></a>
                <a href="#" class="button-select multiple-select-none" data-tooltip="Masquer tous les pins sur la carte"><span class="icomoon-select-none show-hide"></span></a>
            </div>
        `;
    }

    static renderIconTags(tags) {
        const individualTags = tags.split(',');

        return OutputHelpers.map(individualTags, tag => {
            tag = tag.trim();

            const iconTag = Reperages.iconTags[tag];
            if (Helpers.isDefined(iconTag)) {
                return `<span class="tag ${iconTag.icon}" style="color: ${iconTag.color};"></span>`;
            }
        });
    }

    static renderOtherTags(tags) {
        const individualTags = tags.split(',');

        return OutputHelpers.map(individualTags, tag => {
            tag = tag.trim();

            const iconTag = Reperages.iconTags[tag];
            if (!Helpers.isDefined(iconTag)) {
                return `<span class="tag">${tag}</span>`;
            }
        });
    }

    handleFilterChange(pointNameFilter, container) {
        const filterName = pointNameFilter.value.trim();

        this.pointFilters.filterName = filterName;

        PointsListRenderer.filterPoints(filterName, container);
    }

    static filterPoints(filterName, container) {
        if (container) {
            container.querySelectorAll('.point').forEach((pointItem) => {
                const itemName = pointItem.getAttribute('data-name');

                /**
                * Compare rules established with regex:
                * 1. Case insensitivity.
                * 2. Match only at the beginning of a word.
                * 3. Allowing separator and special characters: separator characters are allowed in the search.
                * 4. Accented characters are normalized and made insensitive to diacritics.
                * 5. Search terms can match parts of words, and the order of terms is not strict.
                */

                // Normalizes the search filter by eliminating accented marks
                const normalizedFilterName = filterName.normalize('NFD').replace(/\p{Diacritic}/gu, '');

                // Cut up words to filter them individually
                const searchTerms = normalizedFilterName.split(/\s+/);
                let regexPattern = '^';
                for (const elementSearchTerm of searchTerms) {
                    regexPattern += `(?=.*\\b${elementSearchTerm})`;
                }

                const regex = new RegExp(regexPattern, 'i');
                const nameMatch = regex.test(itemName.normalize('NFD').replace(/\p{Diacritic}/gu, ''));

                if (nameMatch) {
                    pointItem.classList.remove('hidden');
                } else {
                    pointItem.classList.add('hidden');
                }

            });
        }
    }

    static renderInfoWrapper(updatedPoint) {
        return `
            <div class="title-row">
                <div class="${updatedPoint.marker} marker displayed"></div>
                <div class="name">${updatedPoint.name}</div>
                <div class="id">${OutputHelpers.shortUuid(updatedPoint.id)}</div>
            </div>
            <div class="details-row">
                <div class="details-location latitude">
                    <div class="title">Latitude</div>
                    <div class="value">${OutputHelpers.round(updatedPoint.latitude, 6)}</div>
                </div>
                <div class="details-location longitude">
                    <div class="title">Longitude</div>
                    <div class="value">${OutputHelpers.round(updatedPoint.longitude, 6)}</div>
                </div>
                <div class="details-tags">
                    ${PointsListRenderer.renderIconTags(updatedPoint.tags)}
                </div>
            </div>
            <div class="tags-row">
                ${PointsListRenderer.renderOtherTags(updatedPoint.tags)}
            </div>
        `;
    }
}

export default PointsListRenderer;
