import BaseController from 'common/BaseController';
import Helpers from 'common/Helpers';
import Alert from 'common/Alert';
import ApiHelper from 'common/ApiHelper';

class LoginController extends BaseController {

    constructor(container) {
        super(container);

        this.setup();
    }

    setup() {
        this.container.innerHTML = `
            <div class="inner-container">
                <div class="login-container">
                    <form id="login-form">
                        <h1>Connexion</h1>
                        <div class="input-group">
                            <label for="username">Utilisateur:</label>
                            <input type="text" id="username" name="username" required>
                        </div>
                        <div class="input-group">
                            <label for="password">Mot de passe:</label>
                            <input type="password" id="password" name="password" required>
                        </div>
                        <button type="submit">Se connecter</button>
                    </form>
                </div>
            </div>
        `;

        // Listeners

        const submitButton = this.container.querySelector('#login-form');
        submitButton.addEventListener('submit', (event) => this.handleLogin(event));
    }

    handleLogin(event) {
        event.preventDefault();

        const formData = new FormData(this.container.querySelector('#login-form'));

        ApiHelper.sendRequest('/api.php?action=login', 'POST', formData)
            .then(response => {
                if (Helpers.isObject(response)) {
                    if (Helpers.isDefined(response.success)) {
                        console.log('Login successful', response);
                        app.hideLogin();
                        app.showProjectsList();
                    } else {
                        Alert.message('Échec de la connexion. Veuillez réessayer.');
                    }
                } else {
                    console.error('Response is not a valid object');
                }
            })
            .catch(ApiHelper.handleError);
    }

    terminate() {
        this.container.innerHTML = '';

        super.terminate();
    }
}

export default LoginController;
