import Alert from 'common/Alert';

class ShareLinkRenderer {
    constructor() {

    }

    render(shareLink) {
        return `
            <div class="share-link-header">
                <div class="title-header">
                    <div class="title">Lien de partage</div>
                </div>
            </div>
            <div id="share-link-wrapper" class="scrollable-content">
                <div class="share-info">
                    <div id="share-message">Voici votre lien de partage :</div>
                    <a id="share-link" href="${shareLink}" target="_blank">${shareLink}</a>
                    <div id="share-copy-link">
                        <span class="icomoon-clippy" id="share-copy-link-message"></span>
                    </div>
                </div>
            </div>
        `;
    }

    copyToClipboard(text) {
        navigator.clipboard.writeText(text).then(() => {
            Alert.message('Lien copié dans le presse-papiers !');
        }).catch(err => {
            console.error('Error copying text:', err);
        });
    }
}

export default ShareLinkRenderer;
