import OutputHelpers from 'common/OutputHelpers';

class ProjectsListRenderer {

    constructor() {
        this.filtersOptionsOpen = false;
        this.projectFilters = {
            filterName: '',
        };
    }
    
    render(projects) {
        return `
            <div id="project-header">
                <div class="section-title">
                    <div class="title">Projets</div>
                    <div id="create-project" data-tooltip="Créer un nouveau projet">
                        <span class="icomoon-plus2" id="create-project-button"></span>
                    </div>
                    <div id="rename-project" data-tooltip="Renommer ou supprimer un projet">
                        <span class="icomoon-gear" id="rename-project-button"></span>
                    </div>
                    <div id="search-project" data-tooltip="Rechercher un projet">
                        <span class="icomoon-search" id="search-project-button"></span>
                    </div>
                </div>
            </div>
            <div class="project-filters ${this.filtersOptionsOpen ? 'visible' : ''}">
                <div class="project-name">
                    <input type="text" name="project-filter-name" id="project-filter-name" value="${this.projectFilters.filterName}" placeholder="Filtrer par nom de projet">
                </div>
            </div>
            <div id="projects-list-wrapper" class="scrollable-content">
                ${OutputHelpers.map(OutputHelpers.sort(projects, ['name'], 'asc'), project => {
                    return `
                        <div class="project" data-id="${project.id}" data-name="${project.name}" class="project-item">
                            <div class="info-wrapper">
                                <div class="title-row">
                                    <div class="name">${project.name}</div>
                                    <div class="id">${OutputHelpers.shortUuid(project.id)}</div>
                                </div>
                            </div>
                            <div class="projects-options hidden">
                                <div class="options-container">
                                    <div class="rename">
                                        <span class="icomoon-pencil project-rename-toggle" data-tooltip="Renommer le projet"></span>
                                    </div>
                                    <div class="delete">
                                        <span class="icomoon-cup project-delete-toggle" data-tooltip="Supprimer le projet"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    `;
                })}
            </div>
        `;
    }

    handleFilterChange(projectNameFilter, container) {
        const filterName = projectNameFilter.value.trim();
        this.projectFilters.filterName = filterName;
        ProjectsListRenderer.filterProjects(filterName, container);
    }
    
    static filterProjects(filterName, container) {
        if (container) {
            container.querySelectorAll('.project').forEach((projectItem) => {
                const itemName = projectItem.getAttribute('data-name');

                /**
                * Compare rules established with regex:
                * 1. Case insensitivity.
                * 2. Match only at the beginning of a word.
                * 3. Allowing separator and special characters: separator characters are allowed in the search.
                * 4. Accented characters are normalized and made insensitive to diacritics.
                * 5. Search terms can match parts of words, and the order of terms is not strict.
                */

                // Normalizes the search filter by eliminating accented marks
                const normalizedFilterName = filterName.normalize('NFD').replace(/\p{Diacritic}/gu, '');

                // Cut up words to filter them individually
                const searchTerms = normalizedFilterName.split(/\s+/);
                let regexPattern = '^';
                for (const elementSearchTerm of searchTerms) {
                    regexPattern += `(?=.*\\b${elementSearchTerm})`;
                }

                const regex = new RegExp(regexPattern, 'i');
                const nameMatch = regex.test(itemName.normalize('NFD').replace(/\p{Diacritic}/gu, ''));

                if (nameMatch) {
                    projectItem.classList.remove('hidden');
                } else {
                    projectItem.classList.add('hidden');
                }

            });
        }
    }
}

export default ProjectsListRenderer;
