class Footer {

	constructor(container) {
		this.container = container;

		this.setup();
	}

	setup() {
		this.container.innerHTML = `
		<div class="inner-container">
			<p>Repérages webapp v<span id="version"></span></p>
		</div>
		`;

		this.version = this.container.querySelector('#version');
	}

	// Setters

	setVersion(value) {
		this.version.innerHTML = value;
	}
}

export default Footer;
