class PictureFormRenderer {
    static render() {
        return `
            <div id="picture-form-wrapper">
                <div id="new-picture-input">
                    <input type="file" id="picture-file-input" accept="image/*" multiple />
                </div>
                <div id="action-new-picture">
                    <span class="icomoon-plus2"></span>
                </div>
            </div>
        `;
    }
}

export default PictureFormRenderer;
