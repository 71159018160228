import ReperagesWebapp from 'common/ReperagesWebapp';

//

const app = new ReperagesWebapp();

window.app = app;

//

import LoadingHelpers from 'common/LoadingHelpers';

(async () => {
	const Tippy = await LoadingHelpers.tippy();
	// eslint-disable-next-line new-cap
	Tippy('body', {
		target: '[data-tooltip]',
		interactive: false,
		allowHTML: true,
		theme: 'tooltip',
		placement: 'top',
		appendTo: document.body,
		zIndex: 2920,
		onShow(instance) {
			const content = instance.reference.getAttribute('data-tooltip');
			if (!content || content === '') {
				return false;
			}

			instance.setContent(content);
		}
	});
})();
