import Helpers from 'common/Helpers';

class LoadingHelpers {
	static getClassName() {
		return 'LoadingHelpers';
	}

	// Modules

	static async tippy() {
		const [module, _] = await Promise.all([
			async () => { return await import(/* webpackExports: ["delegate"] */ 'node_modules/tippy.js'); },
			async () => { await Helpers.loadStyle('vendor/tippy.js/tippy.css', 'tippy-css', '1'); }
		].map(f => f()));

		return module.delegate;
	}

	static async leaflet() {
		const [module, _, __, ___, ____, _____] = await Promise.all([
			async () => {
				const leaflet = await import('node_modules/leaflet');
				await Helpers.loadScript('vendor/leaflet/leaflet.editable.js', 'leaflet-editable-js', '1');
				await Helpers.loadScript('vendor/leaflet/leaflet.contextmenu.js', 'leaflet-contextmenu-js', '1');
				await Helpers.loadScript('vendor/leaflet/leaflet.animatedmarker.js', 'leaflet-animatedmarker-js', '1');
				return leaflet;
			},
			async () => { await Helpers.loadStyle('vendor/leaflet/leaflet.contextmenu.css', 'leaflet-contextmenu-css', '1'); },
			async () => { await Helpers.loadStyle('vendor/leaflet/leaflet.css', 'leaflet-css', '1'); }
		].map(f => f()));

		this.L = module.default; // making it semi-global

		return this.L;
	}

	// Scripts (webpack externals)


}

export default LoadingHelpers;
