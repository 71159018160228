class Helpers {

	/**
	 *
	 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
	 *
	 * @param min
	 * @param max
	 * @returns {int}
	 */
	static getRandomInt(min, max) {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	/**
	 * Rounds a value with the given number of decimals
	 *
	 * @param {number} value
	 * @param {int} decimals
	 * @returns {number}
	 */
	static round(value, decimals = 0) {
		const multiplier = Math.pow(10, decimals);

		return Math.round(value * multiplier)/multiplier;
	}

	/**
	 * @param {number} value
	 * @param {number} min
	 * @param {number} max
	 * @return {number}
	 */
	static clamp(value, min, max) {
		return Math.min(Math.max(min, value), max);
	}

	/**
	 * Calculates an average value of the array
	 *
	 * @param {array} array
	 * @return {number}
	 */
	static avg(array) {
		let count = 0;
		let total = 0;

		for (let i = 0; i < array.length; i++) {
			const value = array[i];
			if (Helpers.isType(value, 'number') && !Number.isNaN(value) && value !== Infinity) {
				total += value;
				count += 1;
			}
		}

		if (count === 0) {
			return 0;
		}

		return total / count;
	}

	/**
	 * Check if variable is defined
	 * @param variable
	 * @returns {boolean}
	 */
	static isDefined(variable) {
		"use strict";

		return (typeof variable !== 'undefined');
	}

	/**
	 * Returns true is value is of given type (object, array, string, number, boolean)
	 *
	 * @param {string} type
	 * @param {object} value
	 * @returns {boolean}
	 */
	static isType(value, type) {
		return !!(value.constructor && value.constructor.name.toLowerCase() === type.toLowerCase());
	}

	/**
	 * Check if variable is a function
	 * @param variable
	 * @returns {boolean}
	 */
	static isFunction(variable) {
		"use strict";

		return (typeof variable === 'function');
	}

	/**
	 * Check if variable is a string
	 * @param variable
	 * @returns {boolean}
	 */
	static isString(variable) {
		"use strict";

		return (typeof variable === 'string');
	}

	/**
	 * Check if variable is an object
	 * @param variable
	 * @returns {boolean}
	 */
	static isObject(variable) {
		"use strict";

		return (typeof variable === 'object');
	}

	/**
	 * Returns an object by path
	 *
	 * ex: const city = Helpers.getNestedObject(user, ['personalInfo', 'address', 0, 'city']);
	 *
	 * @param {object} nestedObj
	 * @param {array} pathArr
	 * @return {*}
	 */
	static getNestedObject(nestedObj, pathArr) {
		return pathArr.reduce((obj, key) =>
			(obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
	}

	/**
	 * Loads css style
	 * @param {string} url
	 * @param {string} id
	 * @param {version|null} version
	 * @return {Promise<unknown>}
	 */
	static loadStyle(url, id, version = null) {
		return new Promise(function (resolve, reject) {
			const existingLink = document.getElementById(id);
			if (existingLink !== null) {
				resolve(existingLink);
				return;
			}

			const link = document.createElement('link');
			link.type = 'text/css';
			link.rel = 'stylesheet';
			link.href = url + (version !== null ? '?'+version : '');
			link.id = id;

			link.onload = () => resolve(link);
			link.onerror = () => reject(new Error(`Style load error for ${url}`));

			document.head.append(link);
		});
	}

	/**
	 * Loads js script
	 * @param {string} url
	 * @param {string} id
	 * @param {version|null} version
	 * @param {string|null} content
	 * @return {Promise<unknown>}
	 */
	static loadScript(url, id, version = null, content = null) {
		return new Promise(function (resolve, reject) {
			const existingScript = document.getElementById(id);
			if (existingScript !== null) {
				if (existingScript.getAttribute('data-ready') === 'true') {
					resolve(existingScript);
				} else {
					setTimeout(() => {
						resolve(existingScript);
					}, 100); // callback with a timeout to let scripts load
				}
				return;
			}

			const script = document.createElement('script');
			script.type = 'text/javascript';
			if (url !== null) {
				script.src = url + (version !== null ? '?' + version : '');
			}
			script.id = id;

			if (content !== null) {
				script.appendChild(document.createTextNode(content));
			}

			script.onload = () => { setTimeout(() => { script.setAttribute('data-ready', 'true'); resolve(script); }, 100); }; // callback with a timeout to let scripts load
			script.onerror = () => reject(new Error(`Script load error for ${url}`));

			document.head.append(script);
		});
	}

	static uuid() {
		return window.crypto.randomUUID().toLowerCase().replaceAll('-', '');
	}

	static emptyPngBase64() {
		return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUAAACnej3aAAAAAXRSTlMAQObYZgAAAApJREFUCNdjYAAAAAIAAeIhvDMAAAAASUVORK5CYII=';
	}

	static mergeArrays(arrayA, arrayB, checkDuplicates = false) {
		const arrayAValid = (Helpers.isDefined(arrayA) && arrayA !== null && Array.isArray(arrayA));
		const arrayBValid = (Helpers.isDefined(arrayB) && arrayB !== null && Array.isArray(arrayB));
		if (!arrayAValid && !arrayBValid) {
			return [];
		} else if (!arrayAValid) {
			return arrayB;
		} else if (!arrayBValid) {
			return arrayA;
		}

		if (checkDuplicates === false) {
			return arrayA.concat(arrayB);
		} else if (checkDuplicates === true) {
			return arrayA.concat(arrayB.filter((item) => !arrayA.includes(item)));
		} else { // check on property
			const duplicateKeys = arrayA.map((item) => item[checkDuplicates]);
			return arrayA.concat(arrayB.filter((item) => !duplicateKeys.includes(item[checkDuplicates])));
		}
	}
}

export default Helpers;
