import OutputHelpers from 'common/OutputHelpers';

class MapStylesRenderer {

    constructor() {
        this.filtersOptionsOpen = false;
        this.filters = {
            filterText: '',
        }
    }

    render(mapStyles, selectedStyleId, token) {
        const sortedMapStyles = OutputHelpers.sort(mapStyles, ['name', 'fr'], 'asc');
        return `
            <div class="map-style-header">
                <div class="title-header">
                    <div class="title">Styles de carte</div>
                    <div id="map-search-filter" data-tooltip="Options de la liste">
                        <span class="icomoon-search" id="search-map-button"></span>
                    </div>
                </div>
            </div>
            <div class="map-style-filters ${this.filtersOptionsOpen ? 'visible' : ''}">
                <div class="map-style-name">
                    <input type="text" name="map-style-search" id="map-style-search" value="${this.filters.filterText}">
                </div>
            </div>
            <div id="map-styles-wrapper" class="scrollable-content">
                ${OutputHelpers.map(sortedMapStyles, mapStyleGroup => {
                    const sortedMaps = OutputHelpers.sort(mapStyleGroup.maps, ['name', 'fr'], 'asc');
                    return `
                        <div class="map-style-group" data-name-group="${mapStyleGroup.name.fr}">
                            <div id="name-group">${mapStyleGroup.name.fr}</div>
                            <div class="map-style-grid">
                                ${OutputHelpers.map(sortedMaps, mapStyle => MapStylesRenderer.renderMapStyleItem(mapStyle, selectedStyleId, token))}
                            </div>
                        </div>
                    `;
                })}
            </div>
        `;
    }

    static renderLoadingIndicator() {
        return `
            <div class="loading"><img src="media/loading_icon.svg"></div>
        `;
    }

    static renderMapStyleItem(mapStyle, selectedStyleId, token) {
        const name = mapStyle.name.fr;
        const thumbnailUrl = mapStyle.templateUrl.replace("{z}", mapStyle.previewTile.z)
            .replace("{x}", mapStyle.previewTile.x)
            .replace("{y}", mapStyle.previewTile.y)
            .replace("{scale}", "@2x")
            .replace("{token}", token);

        return `
            <div class="map-style-item ${(mapStyle.id === selectedStyleId) ? 'selected' : ''}" data-map-style-id="${mapStyle.id}" data-map-style-name="${name}">
                <div class="map-style-thumbnail">
                    <img src="${thumbnailUrl}" alt="${name}" class="map-picture">
                    <div class="map-style-name">${name}</div>
                </div>
            </div>
        `;
    }

    handleFilterChange(searchFilter, container) {
        const filterText = searchFilter.value.trim();
        this.filters.filterText = filterText;
        MapStylesRenderer.filterStyleMap(filterText, container);
    }

    static filterStyleMap(filterText, container) {
        if (container) {
            const normalizedFilterText = filterText.normalize('NFD').replace(/\p{Diacritic}/gu, '');

            const searchTerms = normalizedFilterText.split(/\s+/);

            let regexPattern = '^';
            for (const elementSearchTerm of searchTerms) {
                regexPattern += `(?=.*\\b${elementSearchTerm})`;
            }
            const regex = new RegExp(regexPattern, 'i');

            container.querySelectorAll('.map-style-group').forEach((groupItem) => {
                const groupName = groupItem.getAttribute('data-name-group').normalize('NFD').replace(/\p{Diacritic}/gu, '');
                let anyVisibleStyles = false;

                groupItem.querySelectorAll('.map-style-item').forEach((styleItem) => {
                    const styleName = styleItem.getAttribute('data-map-style-name').normalize('NFD').replace(/\p{Diacritic}/gu, '');

                    const groupMatch = regex.test(groupName);
                    const styleMatch = regex.test(styleName);

                    if (groupMatch || styleMatch) {
                        styleItem.classList.remove('hidden');
                        anyVisibleStyles = true;
                    } else {
                        styleItem.classList.add('hidden');
                    }
                });

                if (anyVisibleStyles) {
                    groupItem.classList.remove('hidden');
                } else {
                    groupItem.classList.add('hidden');
                }
            });
        }
    }

    reapplyFilter(container) {
        const filterText = this.filters.filterText;
        if (filterText) {
            MapStylesRenderer.filterStyleMap(filterText, container);
        }
    }
}

export default MapStylesRenderer;
