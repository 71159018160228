// import Helpers from 'common/Helpers';

class Reperages {

    static iconTags = {
        'OK': { 'icon': 'icomoon-checkmark', color: '#57B187' },
        'KO': { 'icon': 'icomoon-cross', color: '#DC6B78' },
        'Approuvé': { 'icon': 'icomoon-thumbs-up', color: '#6BA9D7' },
        'Rejeté': { 'icon': 'icomoon-thumbs-down', color: '#866345' },
        'Drapeau plein': { 'icon': 'icomoon-flag', color: '#78CBEA' },
        'Drapeau vide': { 'icon': 'icomoon-flag-o', color: '#78CBEA' },
        'En attente': { 'icon': 'icomoon-hourglass', color: '#f0f0f0' },
        'A rechercher': { 'icon': 'icomoon-medias', color: '#f0f0f0' },
        'Photos': { 'icon': 'icomoon-star', color: '#f0f0f0' },
        'Etoile': { 'icon': 'icomoon-heart', color: '#FFDE05' },
        'Coeur': { 'icon': 'icomoon-search', color: '#E784C5' },
        'A revoir': { 'icon': 'icomoon-cw', color: '#f0f0f0' },
        'Attention': { 'icon': 'icomoon-warning', color: '#F68B1B' },
    };

    static pins = [
        'marker-red', 'marker-green', 'marker-yellow', 'marker-orange', 'marker-blue', 'marker-lightblue', 'marker-purple', 'marker-pink', 'marker-black', 'marker-white', 'marker-grey',
        'pin-red', 'pin-green', 'pin-yellow', 'pin-orange', 'pin-blue', 'pin-lightblue', 'pin-purple', 'pin-pink', 'pin-black', 'pin-white', 'pin-grey',
        'sign-red', 'sign-green', 'sign-yellow', 'sign-orange', 'sign-blue', 'sign-lightblue', 'sign-purple', 'sign-pink', 'sign-black', 'sign-white', 'sign-grey',
        'flag-red', 'flag-green', 'flag-yellow', 'flag-orange', 'flag-blue', 'flag-lightblue', 'flag-purple', 'flag-pink', 'flag-black', 'flag-white', 'flag-grey', 'flag-checkered', 'flag-pirates'
    ];
}

export default Reperages;
