class ProjectRenameRenderer {
    static render(project) {
        return `
            <div class="rename-project-form-header">
                <div class="title-header">
                    <div class="title">Renommage du projet</div>
                </div>
            </div>
            <div id="rename-form">
                <div id="new-project-name-container">
                    <label for="new-project-name" id="title-new-project-name">Nom du projet</label>
                    <input type="text" name="new-project-name" id="new-project-name" value="${project.name}" placeholder="Nouveau nom de projet">
                </div>
                <div id="project-submit">
                    <button class="rename-project-button">Renommer ce projet</button>
                </div>
            </div>
        `;
    }
}

export default ProjectRenameRenderer;
