class TagFormRenderer {
    static render() {
        return `
            <div id="tag-form-wrapper">
                <div id="new-tag-input">
                    <input type="text" id="new-tag">
                </div>
                <div id="action-new-tag">
                    <span class="icomoon-plus2"></span>
                </div>
            </div>
        `;
    }
}

export default TagFormRenderer;
