import Helpers from 'common/Helpers';
import OutputHelpers from 'common/OutputHelpers';

import Reperages from 'common/reperages/Reperages';

class PointDetailsRenderer {

    constructor() {

    }

    static render(point, projectId, isEditable) {
        return `
            <div id="points-details-wrapper" class="scrollable-content">
                <div class="point-details-name section">
                    <div class="section-title">
                        <div class="title">Point</div>
                        <div class="id">${point.id}</div>
                    </div>
                    ${isEditable ? `
                        <input type="text" class="name" value="${point.name}" data-marker-id="${point.id}" />
                    ` : `
                        <div class="name">${point.name}</div>
                    `}
                </div>

                <div class="point-details-location section">
                    <div class="latitude">
                        <div class="title">Latitude</div>
                        <div class="value">${OutputHelpers.round(point.latitude, 6)}</div>
                    </div>
                    <div class="longitude">
                        <div class="title">Longitude</div>
                        <div class="value">${OutputHelpers.round(point.longitude, 6)}</div>
                    </div>
                    <div class="actions">
                        <a href="https://www.google.com/maps?q=${point.latitude},${point.longitude}" class="open-in-maps" target="_blank" rel="noopener noreferrer" data-tooltip="Voir dans Google Maps">
                            <span class="icomoon-directions direction"></span>
                        </a>
                    </div>
                </div>

                <div class="point-details-pin section">
                    <div class="section-title">
                        <div class="title">Pin</div>
                    </div>
                    <div class="point-details-pin-contents scrollable-content">
                        <ul class="pins-list">
                            ${PointDetailsRenderer.renderPinList(point.marker)}
                        </ul>
                    </div>
                </div>

                <div class="point-details-tags section">
                    <div class="section-title">
                        <div class="title">Tags</div>
                        ${isEditable ? `
                            <div id="action-tag">
                                <span class="icomoon-plus2" id="add-tags-button"></span>
                            </div>
                        ` : ''}
                    </div>
                    <div class="point-details-tags">
                        <ul class="tags-list">
                            ${PointDetailsRenderer.renderAllIconTags(point.tags)}
                        </ul>
                        <div class="other-tags-list">
                            ${PointDetailsRenderer.renderOtherTags(point.tags)}
                        </div>
                    </div>
                </div>

                <div class="point-details-notes section">
                    <div class="section-title">Notes</div>
                    ${isEditable ? `
                        <textarea class="notes" data-marker-id="${point.id}">${OutputHelpers.escapeHtml(point.notes)}</textarea>
                    ` : `
                        <div class="notes">${OutputHelpers.escapeHtml(point.notes)}</div>
                    `}
                </div>

                <div class="point-details-pictures section">
                    <div class="section-title">
                        <div class="title">Photos</div>
                        ${isEditable ? `
                            <div id="action-picture">
                                <span class="icomoon-plus2" id="add-pictures-button"></span>
                            </div>
                        ` : ''}
                    </div>
                    <ul class="items">
                        ${PointDetailsRenderer.renderPictures(point.pictures, projectId)}
                    </ul>
                </div>
            </div>
        `;
    }

    static renderAllIconTags(tags) {
        const individualTags = tags.split(',').map(tag => {
            return tag.trim();
        });

        const allTags = Object.keys(Reperages.iconTags);
        return OutputHelpers.map(allTags, tag => {
            const iconTag = Reperages.iconTags[tag];
            return `<li class="tag ${iconTag.icon} ${individualTags.includes(tag) ? 'selected' : ''}" data-tag="${tag}"></li>`;
        });
    }

    static renderOtherTags(tags) {
        const individualTags = tags.split(',');

        return OutputHelpers.map(individualTags, tag => {
            tag = tag.trim();

            const iconTag = Reperages.iconTags[tag];
            if (!Helpers.isDefined(iconTag)) {
                return `<span class="tag">${tag}</span>`;
            }
        });
    }

    static renderPinList(selectedPin) {
        return OutputHelpers.map(Reperages.pins, pin => `<li class="${pin === selectedPin ? 'selected' : ''}" data-pin="${pin}"><div class="marker ${pin}"></div></li>`);
    }

    static renderPictures(pictures, projectId) {
        if (pictures.trim().length === 0) {
            return '';
        }
        
        return OutputHelpers.map(pictures.split("\n"), pictureName => {
           const pictureUrl = `/api.php?action=getPicture&name=${pictureName}&projectId=${projectId}&size=thumbnail`;
            if (Helpers.isDefined(pictureUrl)) {
                return `
                    <li class="picture-thumbnail" data-picture="${pictureName}">
                        <img src="${pictureUrl}" alt="${pictureName}">
                    </li>
                `;
            } else {
                return `
                    <li class="picture-filename">
                        ${pictureName}
                    </li>
                `;
            }
        });
    }

    static renderPictureModal(url, isEditable) {
        return `
            <div class="options">
                ${isEditable ? `
                    <div class="delete-button" data-tooltip="Supprimer">
                        <span class="icomoon-cup"></span>
                    </div>
                ` : ''}
                <div class="close-button" data-tooltip="Fermer">
                    <span class="icomoon-cross2"></span>
                </div>
            </div>
            <div class="picture-modal-content">
                <img src="${url}" class="picture">
            </div>
        `;
    }
}

export default PointDetailsRenderer;
