class Header {

	constructor(container) {
		this.container = container;

		this.setup();
	}

	setup() {
		this.container.innerHTML = `
		<div class="inner-container">
			<h1></h1>
		</div>
		`;

		this.title = this.container.getElementsByTagName('h1')[0];
	}

	// Setters

	setTitle(text) {
		this.title.innerHTML = text;
	}

	// Actions

}

export default Header;
