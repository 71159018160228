import Helpers from 'common/Helpers';

class OutputHelpers {

	static _(cb) {
		const res = cb();
		return (Helpers.isDefined(res)) ? res : '';
	}

	static trueFalseElse(value, trueOutput, falseOutput, elseOutput) {
		if (value === true) {
			return trueOutput;
		} else if (value === false) {
			return falseOutput;
		} else {
			return elseOutput;
		}
	}

	static map(array, cb) {
		if (!array) {
			return '';
		}

		return array.map(cb).join('');
	}

	/**
	 * Returns a string if the value is 0, 1 or n
	 *
	 * @param value
	 * @param text0
	 * @param text1
	 * @param textn
	 * @returns {string}
	 */
	static t01n(value, text0, text1, textn) {
		if (value === 0) {
			return text0;
		} else if (value === 1) {
			return text1;
		} else {
			return textn;
		}
	}

	/**
	 * @param {string} string
	 * @return {string}
	 */
	static capitalize(string) {
		if (typeof string !== 'string') { return ''; }

		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	/**
	 * Sorts an array according to locale-aware rules
	 *
	 * @param {array} array An array of objects or strings
	 * @param {string|array|null} property Property to sort the objects on as string, or array of string for nested properties, or null for direct strings comparison
	 * @param {string} order Order as 'asc' or 'desc'
	 * @return {array}
	 */
	static sort(array, property = null, order = 'asc') {
		array.sort((itemA, itemB) => {
			/* eslint-disable no-nested-ternary */
			const a = ''+(property === null ? itemA : (Helpers.isString(property) ? itemA[property] : Helpers.getNestedObject(itemA, property)));
			const b = ''+(property === null ? itemB : (Helpers.isString(property) ? itemB[property] : Helpers.getNestedObject(itemB, property)));
			/* eslint-enable no-nested-ternary */

			if (order === 'asc') {
				// return ((a < b) ? -1 : ((a > b) ? 1 : 0));
				return a.localeCompare(b, undefined, {numeric: true, sensitivity: 'base'});
			} else if (order === 'desc') {
				// return ((a < b) ? 1 : ((a > b) ? -1 : 0));
				return b.localeCompare(a, undefined, {numeric: true, sensitivity: 'base'});
			} else {
				return 0;
			}
		});

		return array;
	}

	/**
	 * Returns a short uuid from a long one
	 *
	 * @param {string} uuid
	 * @return {string}
	 */
	static shortUuid(uuid) {
		return uuid.substr(0, 6);
	}

	/**
	 * Replaces raw newlines by html newlines
	 *
	 * @param {string} text
	 * @return {string}
	 */
	static nl2br(text) {
		return text.replaceAll("\n", '<br>'+"\n");
	}

	/**
	 * Formats a file size as human-readable.
	 *
	 * NB: calculations using 1000-bytes to get values like macOS
	 *
	 * @param {int} size
	 * @param {int} padEnd
	 * @return {string}
	 */
	static filesize(size, padEnd = 0) {
		const i = Math.floor(Math.log(size) / Math.log(1000));
		return ((size / Math.pow(1000, i)).toFixed(2) + '').padEnd(padEnd, '0') + ' ' + ['o', 'Ko', 'Mo', 'Go', 'To'][i];
	}

	/**
	 * Formats a number of seconds as a practical human-readable duration left string
	 *
	 * @param {int} value
	 * @returns {string}
	 */
	static durationLeft(value) {
		const minutes = Math.round(value/60); // using round instead of floor so that 1.6s is displayed as "2" instead of "1", which is more practical

		if (value >= 45) {
			return `${minutes} ${OutputHelpers.t01n(minutes, 'minutes restantes', 'minute restante', 'minutes restantes')}`;
		} else {
			const seconds = Math.floor(value % 60);

			if (seconds > 15) {
				return `30 secondes restantes`;
			} else if (seconds > 0) {
				return `10 secondes restantes`;
			}
		}

		return `&hellip;`;
	}

	/**
	 * Escapes some special html characters to prevent tags from being interpreted
	 *
	 * @param {string} Unsafe string
	 * @returns {string} Safe string
	 */
	static escapeHtml(unsafe) {
		return unsafe.replace(/[&<"'>]/g, function (match) {
			switch (match) {
				case '&':
					return '&amp;';
				case '<':
					return '&lt;';
				case '>':
					return '&gt;';
				case '"':
					return '&quot;';
				case "'":
					return '&#39;';
				default:
					return match;
			}
		});
	}

	/**
	 * Returns a rounded number to 6 decimal places.
	 *
	 * @param {number} num - The number to round.
	 * @param {number} precision
	 * @returns {number}
	 */
	static round(num, precision) {
		const factor = Math.pow(10, precision);
		return Math.round(num * factor) / factor;
	}
}

export default OutputHelpers;
